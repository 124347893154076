<template>
  <section id="dashboard-prediccion">


    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ag-grid-table
          :debug="true"
          :configUrl="historicoPlanificacionesConfig"
          :dataUrl="historicoPlanificacionesData"
          :editUrl="historicoPlanificacionesEdit"
          @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded"
          :actions="actions"
          rowSelection="multiple"
          @selectionChanged="onSelectionChanged"
          
        >

        <template v-slot:actions>
            <div>
              <b-button-toolbar
                aria-label="Toolbar with button groups and input groups"
                justify
              >
                <b-button-group style="margin-bottom: 1rem">
                  <b-button
                    size="sm"
                    :disabled="disableCompareRuns"
                    @click="compareRuns()"
                    variant="primary"
                    >Comparar</b-button
                  >

                
                </b-button-group>
              </b-button-toolbar>
            </div>
          </template>



        </ag-grid-table>
      </b-col>

      <planificacion-descarga-modal ref="planificacion-descarga-modal"></planificacion-descarga-modal>
    </b-row>
  </section>
</template>

<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import useApiServices from '@/services/useApiServices.js';
import PlanificacionDescargaModal from "./PlanificacionDescargaModal.vue";

import axios from "@axios";

import
{
  BRow,
  BCol,

} from "bootstrap-vue";



export default {  
  components: {
    AgGridTable,
    BRow,
    BCol,
    RepeatStatistics,

    PlanificacionDescargaModal

  },

  methods: { 

    compareRuns()
    {

      this.$router.push({ name: 'camanchaca-comparar-planificaciones', query: { ids: this.selectedRows.map(run => run.id) } })

    },

    onSelectionChanged(selectedRows)
    {

      console.log(selectedRows)

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)

    },

    accionDetalles(params){

      this.$router.push({ name: 'camanchaca-planification-detail', params: { planification_id: params.data.id } })
    },

    accionDescarga(params){

      this.$refs['planificacion-descarga-modal'].show(params.data)

    },

    onGridReady(gridApi, gridColumnApi, params) { 

        console.log("Planificaciones > onGridReady", {gridApi, gridColumnApi, params})

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
        console.log("Planificaciones > onGetRowsLoaded", {gridApi, gridColumnApi, params})

        gridColumnApi.autoSizeColumn('actions')
    },


  },

    mounted()
  {

    axios.get(useApiServices.historicoPlanificacionesStatistics)
      .then((response) =>
      {
        this.itemsData = response.data;
      })
      .catch((error) =>
      {

      });


  },


  data() 
  {
    return {

      itemsData: [],

      historicoPlanificacionesConfig: useApiServices.historicoPlanificacionesConfig,
      historicoPlanificacionesData: useApiServices.historicoPlanificacionesData,
      historicoPlanificacionesEdit: useApiServices.historicoPlanificacionesEdit,

      disableCompareRuns: true,
      selectedRows: [],

      actions: [
        { name: "Descarga", icon: "DownloadCloudIcon", action: this.accionDescarga },
        { name: "Detalles", icon: "AlignJustifyIcon", action: this.accionDetalles },
      ],


    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
